import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SvgIconService {
    private readonly environment = inject(EnvironmentService);
    private readonly httpClient = inject(HttpClient);
    private readonly loaded: Record<string, Observable<string>> = {};

    public load(url: string): Observable<string> {
        const formattedUrl = url.startsWith('/') ? url : `/${url}`;

        if (this.loaded[formattedUrl] instanceof Observable) {
            return this.loaded[formattedUrl];
        }

        this.loaded[formattedUrl] = this.httpClient
            .get(`${this.environment.baseHref}${formattedUrl}`, { responseType: 'text' })
            .pipe(shareReplay({ refCount: true }));

        return this.loaded[formattedUrl];
    }
}
