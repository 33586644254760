import { NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { SanitizePipe } from '@big-direkt/utils/shared';
import { SvgIconComponent } from './components/svg-icon.component';
import { SvgIconService } from './services/svg-icon.service';

@NgModule({
    declarations: [SvgIconComponent],
    exports: [SvgIconComponent],
    imports: [AsyncPipe, SanitizePipe],
    providers: [SvgIconService],
})
export class UiSvgIconModule {}
